import React from 'react'
import './DigiCoursesCard.css'
import RatingImg from '../../pages/Products/DigiHunarImages/rating-img.png'

const DigiCoursesCard = ({ courseImg, digiLogo, courseTitle, courseDesc, courseRatings }) => {
    return (
        <>
            <div className="digi-course-card">
                <img src={courseImg} alt="" className="digi-course-img" />
                <div className="digi-logo-link">
                    <img src={digiLogo} alt="" className="digi-logo" />
                    <a href="https://digihunar.com/" className="digi-web-link">DigiHunar</a>
                </div>
                <h1 className="digi-course-title">{courseTitle}</h1>
                <p className="digi-course-para">{courseDesc}</p>
                <div className="digi-course-bottom">
                    <div className="digi-course-bottom-left">
                        <img src={RatingImg} alt="" />
                        <p className="rating-reviews">{courseRatings}</p>
                    </div>
                    <button className="digi-apply-now">Enroll Now</button>
                </div>
            </div>
        </>
    )
}

export default DigiCoursesCard