import React from 'react'
import './DigiTestimonialCard.css'


const DigiTestimonialCard = ({DigiTestHeading, DigiTestPara, DigiTestTraineeImg, TraineeName}) => {
  return (
    <>
    <div className="digi-testimonial-card">
        <h1 className="digi-test-card-heading">{DigiTestHeading}</h1>
        <p className="digi-test-card-para">{DigiTestPara}</p>
        <div className="digi-test-card-bottom">
            <img src={DigiTestTraineeImg} alt="" className="digi-test-img" />
            <span className="digi-test-traineeName">{TraineeName}</span>
        </div>
    </div>
    
    </>
  )
}

export default DigiTestimonialCard