const data = [
   
    {
        jobTitle: 'Digital Marketing Lead',
        desc: 'is looking for an experienced and strategic Digital Marketing Lead to join our team.',
        responsibilities: [
            {
                data: 'Develop and implement digital marketing strategies that align with business objectives and drive revenue growth.'
            },
            {
                data: 'Create and execute campaigns across multiple digital channels, including social media, email marketing, search engine optimization, paid to advertise, and more'
            },
            {
                data: 'Manage and optimize digital marketing campaigns, monitor metrics, and adjust strategies as needed to ensure optimal results.'
            },
            {
                data: ' Collaborate with cross-functional teams, including design, product, and analytics, to create and implement campaigns that align with overall marketing initiatives.'
            },
            {
                data: 'Stay up-to-date with the latest digital marketing trends, technologies, and best practices.'
            },
            {
                data: ' Analyze campaign performance and provide regular reporting to the team.'
            },
        ],
        requirements: [
            {
                data:"Bachelor's degree in marketing, communications, or a related field.",
            },
            {
                data:"2+ years of experience in digital marketing, with a track record of driving business results.",
            },
            {
                data:"Strong understanding of digital marketing channels and tools.",
            },
            {
                data:"Excellent communication skills, with the ability to work collaboratively with cross-functional teams..",
            },
            {
                data:"Strong project management skills.",
            },
        ],
        lastParas:[
            {
                data:'If you are passionate about digital marketing, have a strategic mindset, and have a proven track record of driving results, we encourage you to apply. ',
            },
            {
                data:'We offer a competitive salary, a comprehensive benefits package, and a dynamic work environment. Join our team and help us grow our business through innovative digital marketing strategies.'
            },
            {
                data:'Females are encouraged to apply'
            }
        ],
        postedDate:'28-feb-2023',
        expiresAt:'5-march-2023',
        formLink:'https://forms.gle/dcsmbB2WpabQUGRu8'
    },
    {
        jobTitle: 'Program Manager',
        desc: 'seeking an experienced and motivated program manager to join our step-up skill development program. As program manager, you will be responsible for conducting, leading, and managing the step-up training programs, ensuring their productivity and motivation, and guiding them to achieve the targeted goals.',
        responsibilities: [
            {
                data: 'Plan and conduct training programs, ensuring that deadlines are met and resources are utilized effectively.'
            },
            {
                data: 'Monitor the training programs and team performance, identify areas for improvement and implement appropriate solutions.'
            },
            {
                data: 'Establish and maintain relationships with trainers, and trainees to ensure that the program runs smoothly and meets its goals.'
            },
        ],
        requirements: [
            {
                data:"Bachelor's degree.",
            },
            {
                data:"2+ years of leadership or management experience.",
            },
            {
                data:"Strong interpersonal and communication skills.",
            },
            {
                data:"Proven ability to manage training.",
            },
            {
                data:"Excellent problem-solving skills and the ability to think strategically.",
            },
            {
                data:"Strong organizational skills and attention to detail.",
            },
        ],
        lastParas:[
            {
                data:'We offer a competitive salary, flexible work arrangements, and opportunities for growth and advancement. If you are a motivated and experienced team lead, we encourage you to apply today.'
            }
        ],
        postedDate:'15-feb-2023',
        expiresAt:'25-feb-2023',
        formLink:'https://docs.google.com/forms/d/e/1FAIpQLSe9-j-9EzN3znarcD9sS6bC_ZFY6jNz9DBlmDeQwe1jERFHgg/viewform?usp=sf_link'
    },
]


export default data