import React from 'react'
import './StepupHeroCards.css'


const StepupHeroCards = ({ hcimage, heroheading, heropara }) => {
  return (
    <>
      
        <div className="stepup-hero-card">

          <div className="stepup-hero-card-top">
            <img src={hcimage} alt="" />
            <h1>{heroheading}</h1>
          </div>

          <p>{heropara}</p>

        </div>



    </>
  )
}

export default StepupHeroCards