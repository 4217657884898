import React from 'react'
import './DigiHeroCard.css'

const DigiHeroCard = ({cardHeading, cardImg1, cardImg2, cardImg3, cardImg4}) => {
  return (
    <>
    <div className="digi-hero-card">
        <h1 className="digi-hero-card-head">{cardHeading}</h1>
        <div className="digi-hero-card-bottom">
            <img src={cardImg1} alt="" className="digi-hero-card-img1" />
            <img src={cardImg2} alt="" className="digi-hero-card-img2" />
            <img src={cardImg3} alt="" className="digi-hero-card-img3" />
            <img src={cardImg4} alt="" className="digi-hero-card-img4" />
        </div>

    </div>
    </>
  )
}

export default DigiHeroCard