import React from 'react'
import './TalentGrowthPartners.css'

const TalentGrowthPartners = ({ talentPartnerLogo1 }) => {
  return (
    <>
    <div className="talent-partners-logo-div">
      <img src={talentPartnerLogo1} alt="" className="talent-growth-partner-img"/>
    </div>
    </>
  )
}

export default TalentGrowthPartners