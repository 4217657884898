import React from "react";
import "../Programs/StepUp.css";
import { Helmet } from "react-helmet";
import stepup from "./Stepupimages/stepup.svg";
// import hicon from './Stepupimages/hicon.png'
import heroarrow from "./Stepupimages/hero-arrow.png";
import heroimg from "./Stepupimages/hero-img.png";
import ninjaLogo from "./Stepupimages/ninja-logo.png";
import ninja from "./Stepupimages/ninga.png";
import ninjaMainImg from "./Stepupimages/ninja-main-img.png";
import responsive from "./Stepupimages/responsiveheroimg.svg";
import hd1 from "../../Components/Stepupherocards/d1.svg";
import hd2 from "../../Components/Stepupherocards/d2.svg";
import hd3 from "../../Components/Stepupherocards/d3.svg";
import co1 from "../../Components/Stepupcoursecard/co1.png";
import co2 from "../../Components/Stepupcoursecard/co2.png";
import co3 from "../../Components/Stepupcoursecard/co3.png";
import co4 from "../../Components/Stepupcoursecard/co4.png";
import video from "../../Components/Stepupcoursecard/video.png";
import accounting from "../../Components/Stepupcoursecard/accounting.png";
// import co5 from '../../Components/Stepupcoursecard/co5.png'
import st1 from "../../Components/Stepuptestimonials/st1.png";
import st2 from "../../Components/Stepuptestimonials/st2.png";
import successstoryimg from "./Stepupimages/successstory.png";

import fiver from "./Stepupimages/fiverimg.png";
import quoteimg from "./Stepupimages/quoteup.png";
import { Link } from "react-router-dom";

// components
import StepupHeroCards from "../../Components/Stepupherocards/StepupHeroCards";
import StepupCourseCards from "../../Components/Stepupcoursecard/StepupCourseCards";
import StepupTestimonials from "../../Components/Stepuptestimonials/StepupTestimonials";
import SuccessCard from "../../Components/SuccessCards/SuccessCard";

//Swiper

// import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import "./styles.css";

// import required modules
import { Pagination, Autoplay, Navigation, Scrollbar, Keyboard } from "swiper";

const StepUp = () => {
  return (
    <>
      <Helmet>
        <title>Programs - StepUp</title>
      </Helmet>
      <section className="max-width-1440 stepup-hero-section">
        <div className="stepup-hero-section-slider">
          <Swiper
            slidesPerView={1}
            spaceBetween={50}
            loop={true}
            centeredSlides={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            pagination={{
              // el: '.swiper-pagination',
              // dynamicBullets: true,
              clickable: true,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Scrollbar, Keyboard, Navigation]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className="stepup">
                <div className="stepup-left">
                  <img className="stepup-hero-icon" src={stepup} alt="" />
                  <h1 className="stepup-hero-heading">
                    <span className="primary-color">StepUp Ademia </span>
                    Digital Training Program
                    {/* <span className='heroh1icon'><img src={hicon} alt="" /></span> */}
                  </h1>
                  <p className="stepup-para">
                    Step-Up is a flagship project of IdeoMetriX where we provide
                    skill development and freelance training to young people to
                    help them develop their skills and earn an income. We guide
                    them on how to start, perform, and use their skills in a
                    productive and effective manner.
                  </p>
                  <img className="responsiveimg" src={responsive} alt="" />
                  <div className="stepup-hero-btn">
                    <Link
                      to="https://forms.gle/2PjqP1i92rcVTTHcA"
                      target="_blank"
                    >
                      <button
                        className="hero-get-start"
                        style={{ cursor: "pointer" }}
                      >
                        Register Now
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="stepup-right">
                  <img className="hero-arrow-img" src={heroarrow} alt="" />
                  <img className="hero-right-img" src={heroimg} alt="" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="stepup-ninja">
                <div className="stepup-ninja-left">
                  <img
                    className="stepup-ninja-hero-icon"
                    src={ninjaLogo}
                    alt=""
                  />
                  <span className="stepup-ninja-span">Unleash your inner </span>
                  <div className="stepup-ninja-head-img">
                    <h1 className="stepup-ninja-heading">Tech Ninj</h1>
                    <img src={ninja} alt="" />
                  </div>
                  <div className="stepup-hero-btn">
                    <Link
                    style={{ cursor: "not-allowed" }}
                      to="https://forms.gle/Xn6fpBKsXuTVz2YMA"
                      target="_blank"
                    >
                      <button disabled
                        className="hero-get-start"
                        style={{ cursor: "not-allowed", background: "#676767" }}
                      >
                        Register Now
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="stepup-ninja-right">
                  <img
                    className="stepup-ninja-right-img"
                    src={ninjaMainImg}
                    alt=""
                  />
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>

      {/* --------------Hero-Cards-------------- */}

      <section className="hero-cards max-width-1440">
        <StepupHeroCards
          hcimage={hd1}
          heroheading="500+"
          heropara="Total Trained Students"
        />
        <StepupHeroCards
          hcimage={hd1}
          heroheading="6+"
          heropara="Total Trainers"
        />
        <StepupHeroCards
          hcimage={hd2}
          heroheading="5+"
          heropara="Offered Courses"
        />
        <StepupHeroCards
          hcimage={hd3}
          heroheading="4"
          heropara="Completed Batches"
        />
      </section>

      {/* --------------Course Section------------------- */}

      <div className="stepup-courses-section">
        <div className="max-width-1440">
          <section className="courses-offer">
            <h1 className="course-heading">
              {" "}
              <span className="secondary-color">Courses </span> We Offer
            </h1>
            <p className="course-para">
              We are thrilled to have you on board for your journey toward
              mastering digital skills. Our range of digital courses will assist
              you in becoming an expert in the digital world.
            </p>

            <div className="step-up-course-cards">
              <Swiper
                breakpoints={{
                  1345: {
                    // width:950,
                    slidesPerView: 3,
                  },
                  360: {
                    // width:950,
                    slidesPerView: 3,
                    spaceBetween: 10,
                  },
                }}
                slidesPerView={3}
                spaceBetween={25}
                loop={true}
                centeredSlides={false}
                // autoplay={{
                //   delay: 4000,
                //   disableOnInteraction: false,
                //   pauseOnMouseEnter: true,
                // }}
                pagination={{
                  // el: '.swiper-pagination',
                  dynamicBullets: true,
                  clickable: true,
                }}
                modules={[Pagination, Autoplay]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <StepupCourseCards
                    courseimage={co3}
                    courseheading="Graphic Designing"
                    basicLevelLabel
                    coursepara="Ignite your creativity with our Basic Graphic Design course! Perfect for beginners, you'll learn design principles, color theory, typography, and layout. Gain hands-on experience with Adobe Photoshop, Illustrator, and InDesign. By course end, you'll be ready to create stunning graphics for digital and print media. Join us and start your graphic design journey today!"
                    coursebutton="Register Now"
                    id={1}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <StepupCourseCards
                    courseimage={co1}
                    courseheading="Digital Marketing"
                    basicLevelLabel
                    coursepara="Our comprehensive Digital Marketing Course covers essential topics like Social Media Management, SEO, and Email Marketing, equipping you with the skills to excel in the digital space. Learn to navigate and succeed on platforms like Fiverr and Upwork, mastering freelance opportunities and client engagement. Gain practical knowledge and hands-on experience to enhance your marketing strategies and boost your online presence effectively."
                    coursebutton="Register Now"
                    id={0}
                  />
                </SwiperSlide>
                {/* <SwiperSlide>
                  <StepupCourseCards
                    courseimage={co1}
                    courseheading="Digital Marketing"
                    basicLevelLabel
                    coursepara="You will learn the fundamentals of digital marketing and how it can help your business succeed. You will learn the basics of digital marketing, from content creation to social media, search engine optimization (SEO), and advertising. You will understand Read More..."
                    coursebutton="Register Now"
                    id={0}
                  />
                </SwiperSlide> */}

                <SwiperSlide>
                  <StepupCourseCards
                    courseimage={co2}
                    courseheading="UI UX Designing"
                    basicLevelLabel
                    coursepara="Discover the essentials of UI/UX design in our comprehensive course. Learn to create visually stunning and user-friendly interfaces for websites and apps. Gain practical skills in wireframing, prototyping, and usability testing through hands-on projects. Master UI/UX design and launch your career in digital design."
                    coursebutton="Register Now"
                    id={3}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <StepupCourseCards
                    courseimage={co2}
                    courseheading="UI UX Designing"
                    advanceLevelLabel
                    coursepara="Elevate your design skills with our Advanced UI/UX Design Course. Dive deeper into complex design principles, advanced prototyping, and user research techniques. Learn to create sophisticated, high-impact user experiences for web and mobile applications. Perfect for experienced designers looking to refine their expertise and tackle more challenging projects."
                    coursebutton="Register Now"
                    id={3}
                  />
                </SwiperSlide>

                <SwiperSlide>
                  <StepupCourseCards
                    courseimage={co4}
                    courseheading="Frontend Development"
                    basicLevelLabel
                    coursepara="In our Web Development Basics course, you'll learn the foundational skills to create stunning websites using HTML and CSS. This course covers the essential HTML tags for structuring web content and the powerful CSS properties to style and layout your pages. By the end of the course, you'll be able to build responsive, visually appealing websites from scratch."
                    coursebutton="Register Now"
                    id={4}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <StepupCourseCards
                    courseimage={co4}
                    courseheading="Frontend Development (React)"
                    advanceLevelLabel
                    coursepara="In our Advanced Web Development course, you'll master JavaScript for dynamic web functionality and React for building interactive user interfaces. By the end, you'll be able to create sophisticated, high-performance web applications."
                    coursebutton="Register Now"
                    id={4}
                  />
                </SwiperSlide>

                {/* <SwiperSlide>
                  <StepupCourseCards
                    courseimage={co3}
                    courseheading="Graphic Designing"
                    basicLevelLabel
                    coursepara="Ignite your creativity with our Basic Graphic Design course! Perfect for beginners, you'll learn design principles, color theory, typography, and layout. Gain hands-on experience with Adobe Photoshop, Illustrator, and InDesign. By course end, you'll be ready to create stunning graphics for digital and print media. Join us and start your graphic design journey today!"
                    coursebutton="Register Now"
                    id={1}
                  />
                </SwiperSlide> */}
                <SwiperSlide>
                  <StepupCourseCards
                    courseimage={co3}
                    courseheading="Graphic Designing"
                    advanceLevelLabel
                    coursepara="Refine your design skills with our Advanced Graphic Design course! Perfect for those with a basic foundation, you'll master advanced techniques in Adobe Photoshop, Illustrator, and Explore branding. By course end, you'll be ready to tackle professional design projects with confidence. Join us to take your graphic design expertise to the next level!"
                    coursebutton="Register Now"
                    id={2}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <StepupCourseCards
                    courseimage={video}
                    courseheading="Video Editing"
                    basicLevelLabel
                    coursepara="Transform your creativity with our video editing course! Perfect for beginners and intermediates, you'll learn essential techniques, from cutting and merging footage to adding effects and enhancing audio. Gain hands-on experience with Adobe Premiere Pro and Final Cut Pro. By course end, you'll be ready to create professional-quality videos for any purpose. Join us and start your journey in video storytelling today!"
                    coursebutton="Register Now"
                    id={2}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <StepupCourseCards
                    courseimage={accounting}
                    courseheading="Bookkeeping"
                    basicLevelLabel
                    coursepara="Boost your financial skills with our bookkeeping course! Perfect for beginners and those looking to sharpen their expertise, you'll learn to manage records, perform data entry, and understand financial statements. Gain hands-on experience with QuickBooks and Excel. By course end, you'll be ready to handle bookkeeping for small businesses or advance in your accounting career. Join us and master the essentials of bookkeeping!"
                    coursebutton="Register Now"
                    id={2}
                  />
                </SwiperSlide>
              </Swiper>
            </div>
          </section>
        </div>
      </div>

      {/* ------------------------Success Stories-------------------------- */}

      <div className="success-stories-section">
        <div className="max-width-1440">
          <section className="success-stories">
            <h1 className="success-stories-heading">
              {" "}
              Trainees <span className="secondary-color">Success</span> Stories
            </h1>
            <p className="success-stories-para">
              Get to know about our Alumni’s success story and what they say
              about our courses.
            </p>

            <div className="success-stories-cards">
              <Swiper
                slidesPerView={1}
                spaceBetween={50}
                loop={true}
                centeredSlides={true}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true,
                }}
                navigation={true}
                modules={[Autoplay, Navigation]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <SuccessCard
                    successzimg={successstoryimg}
                    successleftheading="ZEESHAN AHMED (BATCH 03)"
                    successleftpara="Got his first project from Fiverr for $140 just after completing his UX/UI designing course from the Step Up skill development program."
                    successfiverimg={fiver}
                    successclheading="GOT HIS FIRST PROJECT FROM&nbsp;"
                    successclgreenheading="FIVERR."
                    successcrleftheading="EARNING $140"
                    successquoteimg={quoteimg}
                    successrightpara="For the first time, I enrolled myself in the UX/UI designing course with Step-up skill development program. it was a new and unique experience I had. the trainer and co-trainers were well-experienced, cooperative, and helpful. they helped me with my project and guided me step by step. I will appreciate the management for making such an environment where you’re allowed to learn more and more."
                    successResponsh="EARNING $140"
                  />
                </SwiperSlide>
              </Swiper>
            </div>
          </section>
        </div>
      </div>

      {/* --------------------Stepup Ninja---------------------- */}

      <section className="stepup-ninja-section max-width-1440px">
        <div className="stepup-ninja-section-inner">
          <h1 className="stepupinnja-head">
            <span className="stepup-primary-color">StepUp </span>Ninja's
          </h1>
          <span className="stepupinnja-span">
            We have an amazing program to teach students about programming,
            critical thinking and problem solving techniques
          </span>
          <div className="stepup-section-bottom">
            <div className="stepup-section-bottom-left">
              <span className="stepup-ninja-span stepupninja-inner-span">Unleash your inner </span>
              <div className="stepup-ninja-head-img ninja-bottom-head-img">
                <h1 className="stepup-ninja-heading stepupninja-inner-head">Tech Ninj</h1>
                <img src={ninja} alt="" />
              </div>
              <div className="stepup-hero-btn">
                <Link to="https://forms.gle/Xn6fpBKsXuTVz2YMA" target="_blank" style={{cursor: 'not-allowed'}}>
                  <button
                  disabled
                    className="hero-get-start"
                    style={{ cursor: "not-allowed", background: "#676767" }}
                  >
                    Register Now
                  </button>
                </Link>
              </div>
            </div>
            <div className="stepup-section-bottom-right">
              <img src={ninjaMainImg} alt="" />
            </div>
          </div>
        </div>
      </section>

      {/* ----------------------stepup-testimonials---------------------- */}

      <section className="stepup-testimonials">
        <h1 className="stepup-testimonial-heading">
          <span className="secondary-color">Trainees </span>Testimonials
        </h1>
        <p className="stepup-testimonial-para">
          What our trainees say about us!{" "}
        </p>

        <div className="stepup-testimonial-cards">
          <Swiper
            breakpoints={{
              426: {
                slidesPerView: 2,
                spaceBetween: 0,
              },
              360: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
            }}
            slidesPerView={2}
            spaceBetween={0}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            loop={true}
            modules={[Pagination, Autoplay, Navigation]}
            className="mySwiper"
          >
            <SwiperSlide>
              <StepupTestimonials
                testimonialimage={st1}
                testimonialpara="The pattern of the highly technical background of UI UX along with graphic designing was a journey with step up was an extremely remarkable experience. My journey of freelancing was possible because of well-equipped technology Read More..."
                testimonialname="Zahid Hussain"
              />
            </SwiperSlide>
            <SwiperSlide>
              <StepupTestimonials
                testimonialimage={st2}
                testimonialpara="Before the training, I was unaware of the scope of computers and the term freelancing was new to me. The training helped me to develop my computer skills, including learning the basics of UI/UX, and how to use tools such Read More..."
                testimonialname="Sajila Naz"
              />
            </SwiperSlide>
            <SwiperSlide>
              <StepupTestimonials
                testimonialimage={st1}
                testimonialpara="The pattern of the highly technical background of UI UX along with graphic designing was a journey with step up was an extremely remarkable experience. My journey of freelancing was possible because of well-equipped technology Read More..."
                testimonialname="Zahid Hussain"
              />
            </SwiperSlide>
            <SwiperSlide>
              <StepupTestimonials
                testimonialimage={st2}
                testimonialpara="Before the training, I was unaware of the scope of computers and the term freelancing was new to me. The training helped me to develop my computer skills, including learning the basics of UI/UX, and how to use tools such Read More..."
                testimonialname="Sajila Naz"
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
    </>
  );
};

export default StepUp;
