import branding from '../../Components/FeaturesCard/branding.svg'
import designing from '../../Components/FeaturesCard/designing.svg'
import developnemt from '../../Components/FeaturesCard/development.svg'
import marketing from '../../Components/FeaturesCard/marketing.svg'
import writing from '../../Components/FeaturesCard/writing.svg'
import graphics from '../../Components/FeaturesCard/graphics.svg'

import DigiHunar from '../../Components/products/digihunar.svg'
import Hotela from '../../Components/products/hotela.svg'

import sliderQouts from '../../Components/TestimonialSlider/double-qouts.png'
import ErumPic from '../../Components/TestimonialSlider/erum.png'
import KamalPic from '../../Components/TestimonialSlider/Kamalpic.png'
// import AP from '../../pages/Programs/TalentImages/ap.png'
import AP from './Home-images/company.png'

export const homeCardData = [
    {
        image:branding,
        heading:"Corporate Branding",
        // cardPara:"We’re driven beyond just finishing projects. We want to find smart solutions."
        cardPara:"We assist businesses to reach a larger audience and drive higher engagements by creating powerful branding, rebranding, brand awareness, and design solutions to create a strong ..."
    },
    {
        image:designing,
        heading:"UIUX Designing",
        cardPara:"By creating user-friendly and visually appealing user interfaces and user experiences for web and mobile applications. We help businesses to create a product that is both intuitive and ..."
    },
    {
        image:developnemt,
        heading:"Web Development",
        cardPara:"We offer complete website development solutions that cater to the specific requirements and preferences of our clients. We provide services ranging from design and develop ..."
    },
    {
        image:marketing,
        heading:"Digital Marketing",
        cardPara:"We assist businesses by creating search-engine-optimized content such as website copy, blog posts, articles, press releases, etc. that are engaging, informative, and up-to-date with the latest ...."
    },
    {
        image:writing,
        heading:"Content Writing",
        cardPara:"We assist businesses to reach their target audience and improve their online presence with our marketing plans and strategies. Our services include search engine optimization ..."
    },
    {
        image:graphics,
        heading:"Graphic Designing",
        cardPara:"We provide a full range of graphic design services, including branding materials, social media graphics, web design, and print materials. Our goal is to deliver exceptional designs that leave a ..."
    },
]


export const productData = [
    {
        image:DigiHunar,
        productsPara:"DigiHunar is an online professional skill development platform by IdeoMetriX that offers a range of distant learning courses. These courses are scheduled independently and follow multi-tier strategies to ensure optimal learning and career growth. Additionally, the platform help students to discover opportunities that align with their skills and help them earn money.",
        // productsPara:"This Factory for Good is our way of taking action to help those in need and make a lasting impact on the world. It will be a rewarding journey, and we hope that you join us in this mission. Together, we can create a better future for everyone.",
        productsLink:"www.digihunar.com"
    },
    {
        image:Hotela,
        productsPara:"Hotela application is a product of IdeoMetriX. The purpose of developing this application is to address issues in the hospitality industry. The main objective is to simplify the booking process for the hotel industry while also using the app to receive orders, ideas, and complaints from customers and tourists. This app serves as a platform to bridge the gap between..." ,
        // productsPara:"This Factory for Good is our way of taking action to help those in need and make a lasting impact on the world. It will be a rewarding journey, and we hope that you join us in this mission. Together, we can create a better future for everyone." ,
        productsLink:"www.hotela.com"
    },

]

export const testimonialData = [
    {
        sliderQouts: sliderQouts,
        testSliderImg: ErumPic,
        testSliderPara: "From day one IdeoMetrix has been providing exemplary services to OEC in all aspects ranging designing social media posts, banners and standees along with complementary logo designs. Their assigned tasks have been on time throughout the year with an extraordinary work quality.",
        testClientName: "Erum Fatima",
        testClientPosition: "President OEC"
    },
    {
        sliderQouts: sliderQouts,
        testSliderImg: KamalPic,
        testSliderPara: "When our design needs expert opinion or review I know I can rely on your Agency. This is one of the most professional teams I have ever seen regarding Software and Design Solutions. quality.",
        testClientName: "Kamal Uddin",
        testClientPosition: "CEO at Kamal Energies"
    },
    {
        sliderQouts: sliderQouts,
        testSliderImg: AP,
        testSliderPara: "Our experience with Ideometrix was truly exceptional. Their team's talent, creativity, professionalism, and attention to detail were evident in every aspect of their work. I wholeheartedly recommend them to anyone seeking top-notch graphic design services. Whether you need a logo, branding materials, or a complete visual overhaul, this agency will undoubtedly deliver outstanding results.",
        testClientName: "Accelerate Prosperity",
    },
]