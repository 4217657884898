import React from 'react'
import {MdArrowForwardIos} from 'react-icons/md'
import './Card.css'


const featuresCard = ({data}) => {

  // console.log(data)
  return (
    <>
    {
      data && (
        <div className="features-card">
        <img src={data.image} alt="" />
        <h1>{data.heading}</h1>
        <p>{data.cardPara}</p>
        <a href="/"> <span className='primary-color'>Learn more </span><MdArrowForwardIos className='primary-color icon-font'/></a>
    </div>
      )
    }
    {/*  */}
    </>
  )
}

export default featuresCard