import React from 'react'
import HomeRecentWork from '../../HomeRecentWork/HomeRecentWork'
import graphicsWork1 from '../Branding/Branding-Images/recent-work1.png'
import graphicsWork2 from '../Branding/Branding-Images/recent-work2.png'
import graphicsWork3 from '../Branding/Branding-Images/recent-work3.png'
import graphicsWork4 from '../Branding/Branding-Images/recent-work4.png'
import graphicsWork5 from '../Branding/Branding-Images/recent-work5.png'
import graphicsWork6 from '../Branding/Branding-Images/recent-work6.png'

const Branding = () => {
  return (
    <div className="recent-work-images">

                <HomeRecentWork recentWorkImg1={graphicsWork1} recentWorkHeading="National Tourism Incubator Design" />
                <HomeRecentWork recentWorkImg1={graphicsWork2} recentWorkHeading="Tourism Startup Bootcamp" />
                <HomeRecentWork recentWorkImg1={graphicsWork3} recentWorkHeading="Ideometrix Post Design" />
                <div className="dsply-none">
                  <HomeRecentWork recentWorkImg1={graphicsWork4} recentWorkHeading="Early childhood Development" />
                </div>
                <div className="dsply-none">
                  <HomeRecentWork recentWorkImg1={graphicsWork5} recentWorkHeading="Apex Brand Identity Design" />
                </div>
                <div className="dsply-none">
                  <HomeRecentWork recentWorkImg1={graphicsWork6} recentWorkHeading="Mockup Design" />
                </div>
              </div>
  )
}

export default Branding