// import React from 'react'
import React, { useEffect, useRef, useState } from 'react'
import '../CareersPage/CareersPage.css'
import startTime from '../CareersPage/timer-start.png'
import endTime from '../CareersPage/timer-pause.png'
import briefCase from '../CareersPage/briefcase.png'
import locationIcon from '../CareersPage/location.png'
import JobCard from './JobCard/JobCard'
import jobData  from './jobData'
const CareersPage = () => {
  const heightRef = useRef();
  const [height, setHeight] = useState(false)
  const [date, setDate] = useState(0)
  const handleLearnMore = () => {

    if (heightRef.current.style.maxHeight.length === 0 || heightRef.current.style.maxHeight === '63px') {
      setHeight(true)
      heightRef.current.style.maxHeight = '676px'
    } else {
      setHeight(false)
      heightRef.current.style.maxHeight = '63px'
    }
  }
  return (
    <>
      <section className="careers-hero-section">
        <div className="max-width-1440">
          <div className="careers-hero">
            <span className="carrers-apply-now">Apply now to join our diverse team and work with us.</span>
            <h1 className="careers-hero-heading">We believe in <span className="primary-color">diversity </span> and we work with it</h1>
          </div>
        </div>
      </section>
      <section className="current-openings-section">
        <div className="max-width-1440">
          <div className="careers-current-openings">
            <h1 className="careers-current-openings-head">Current Openings</h1>

            {
              jobData.map((job,index)=>{
                return <JobCard key={index} data={job}/>
              })
            }
            
          </div>
        </div>
      </section>
    </>
  )
}

export default CareersPage