import React from 'react'
import './style.css'
import { Link } from 'react-router-dom'
const FormSubmit = () => {
  return (
    <div className='form-submit-container'>
        <h1>Your Response Has Been Submitted</h1>
        <Link to="/"><button>Go To Homepage</button></Link>
    </div>
  )
}

export default FormSubmit