import React from 'react'
import './StepupTestimonials.css'

const StepupTestimonials = ({ testimonialimage, testimonialpara, testimonialname }) => {
    return (
        <>
            <div className="stepup-testimonials-card">
                <div className="stepup-testimonials-top">
                    <img src={testimonialimage} alt="" />
                    <p >{testimonialpara}</p>
                </div>
                <h1 className='testimon-headeing'>{testimonialname}</h1>
            </div>
        </>
    )
}

export default StepupTestimonials