import React from 'react'
import '../PageNotFound/PageNotFound.css'
import Error404 from '../PageNotFound/error404.png'

const PageNotFound = () => {
  return (
    <>
    <div className="max-width-1440">
      <div className="error404-msg">
        <img src={Error404} alt="" />
        <h1 className='error-404'>This page doesn't exists on our website</h1>
      </div>
    </div>
    </>
  )
}

export default PageNotFound