import React from 'react'
import HomeRecentWork from '../../HomeRecentWork/HomeRecentWork'
import recentWork1 from '../../../pages/Home/Home-images/recent-work1.png'
import recentWork2 from '../../../pages/Home/Home-images/recent-work2.png'
import recentWork3 from '../../../pages/Home/Home-images/recent-work3.png'
import recentWork4 from '../../../pages/Home/Home-images/recent-work4.png'
import recentWork5 from '../../../pages/Home/Home-images/recent-work5.png'

const UIUX = () => {
  return (
    <div className="recent-work-images">

                <HomeRecentWork recentWorkImg1={recentWork1} recentWorkHeading="Website Landing Page Developement" />
                <HomeRecentWork recentWorkImg1={recentWork2} recentWorkHeading="Website Landing Page Developement" />
                <HomeRecentWork recentWorkImg1={recentWork3} recentWorkHeading="Website Landing Page Developement" />
                <div className="dsply-none">
                  <HomeRecentWork recentWorkImg1={recentWork4} recentWorkHeading="Website Landing Page Developement" />
                </div>
                <div className="dsply-none">
                  <HomeRecentWork recentWorkImg1={recentWork5} recentWorkHeading="Website Landing Page Developement" />
                </div>
                <div className="dsply-none">
                  <HomeRecentWork recentWorkImg1={recentWork5} recentWorkHeading="Website Landing Page Developement" />
                </div>
              </div>
  )
}

export default UIUX