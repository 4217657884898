import React, { createRef, useRef, useState } from 'react'
import '../ourTeamCard/teamCard.css'
import Mohsin from '../../pages/About/About-Imagess/mohsin.png'
import linkedInIcon from '../../pages/About/About-Imagess/linkedin.png'

const TeamCard = ({ data, index, elementsRef }) => {
  // console.log(data)
  const sizeRef = useRef(window.innerWidth)

  // console.log(sizeRef)
  const [size, setSize] = useState(sizeRef)

  window.addEventListener("resize", function () {
    setSize(window.innerWidth)
  });

  return (

    <>
      <div className="team-card">
        <img src={data.teamMemberPhoto} alt="" />
        <div className="team-member-details">
          <span className="team-member-name">{data.memberName}</span>
          <span className="team-member-position">{data.memberPosition}</span>
          <p className="team-member-detail">{ data.memberDetail } <button className="seeMore-btn primary-color">See More</button></p>
          <div className="member-social-account">
            <span className="followat">Follow at</span>
            <a href={data.link} target="_blank"><img src={linkedInIcon} alt="" /></a>
          </div>
        </div>
      </div>
    </>
  )
}

export default TeamCard