import React from 'react'
import HomeRecentWork from '../../HomeRecentWork/HomeRecentWork'
import mblWork1 from '../App/Mobile-Images/recent-work1.png'
import mblWork2 from '../App/Mobile-Images/recent-work2.png'
import mblWork3 from '../App/Mobile-Images/recent-work3.png'
import mblWork4 from '../App/Mobile-Images/recent-work4.png'
import mblWork5 from '../App/Mobile-Images/recent-work5.png'
import mblWork6 from '../App/Mobile-Images/recent-work6.png'

const MobileApp = () => {
  return (
    <div className="recent-work-images">

                <HomeRecentWork recentWorkImg1={mblWork1} recentWorkHeading="Quran App Wireframe Design" />
                <HomeRecentWork recentWorkImg1={mblWork2} recentWorkHeading="Blood App Design" />
                <HomeRecentWork recentWorkImg1={mblWork3} recentWorkHeading="Finance App Design" />
                <div className="dsply-none">
                  <HomeRecentWork recentWorkImg1={mblWork4} recentWorkHeading="Food App Design" />
                </div>
                <div className="dsply-none">
                  <HomeRecentWork recentWorkImg1={mblWork5} recentWorkHeading="Wallet App Design" />
                </div>
                <div className="dsply-none">
                  <HomeRecentWork recentWorkImg1={mblWork6} recentWorkHeading="Blood App Design" />
                </div>
              </div>
  )
}

export default MobileApp