import React from 'react'
import '../Products/Hotela.css'
import hotelaheroimg from './Hotelaimages/comingup.png'
import { Helmet } from 'react-helmet'


const Hotela = () => {
  return (
    <>
    <Helmet>
      <title>Products - Hotela</title>
    </Helmet>

      <section className="Hotela-section">
        <div className="hotela-hero-section">
          <img className='hotela-hero-img' src={hotelaheroimg} alt="" />
          <p className="hotela-hero-para">Our team is working, this page will be live soon!</p>

        </div>

      </section>

    </>
  )
}

export default Hotela