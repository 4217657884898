import React from 'react'
import './TalentGrowthCard.css'
import TalentGrowthImg from '../../pages/Programs/TalentImages/talentGrowthCard.png'

const TalentGrowthCard = ({talentGrowthTopImg, talentGrowthNumbers, cardFirstHead, cardSecondHead} ) => {
  return (
    <>
        <div className="talent-growth-card">
            <img className='talent-growth-img' src={TalentGrowthImg} alt="" />
            <div className="talent-growth-card-top">
                <img className='talent-growth-top-img' src={talentGrowthTopImg} alt="" />
            </div>
            <span className="talent-growth-card-number">{talentGrowthNumbers}</span>
            <h1 className="talent-growth-card-head">{cardFirstHead } <span className="primary-color">{ cardSecondHead}</span></h1>
        </div>
    </>
  )
}

export default TalentGrowthCard