import React from "react";
import "./StepupCourseCards.css";
import { Link } from "react-router-dom";
import { useState } from "react";
const StepupCourseCards = ({
  courseimage,
  courseheading,
  basicLevelLabel,
  advanceLevelLabel,
  coursepara,
  coursebutton,
  id,
}) => {
  let [buttonStyle, setButtonStyle] = useState({});
  let [isButtonDisabled, setIsButtonDisabled] = useState(false);

  React.useEffect(() => {
    if (id === 1) {
      setButtonStyle({ backgroundColor: "#26344E", cursor: "pointer" });
      setIsButtonDisabled(false);
    } else {
      
      setButtonStyle({ backgroundColor: "#727272", cursor: "pointer" });
      setIsButtonDisabled(true);
    }
  }, [id]);
  return (
    <>
      <div className="stepup-course-cards">
        <div className="stepUp-course-card-icon-title">
          <img src={courseimage} alt="" />
          <div className="stepUp-course-title-level">

          <h1>{courseheading}</h1>
          {(basicLevelLabel && (
            <div className="basic-level-course">Basic Course</div>
          )) ||
            (advanceLevelLabel && (
              <div className="advance-level-course">Advance Course</div>
            ))}

          {/* {basicLevelLabel ? (
            <div className="basic-level-course">{basicLevelLabel}</div>
          ) : (
            advanceLevelLabel && <div>{advanceLevelLabel}</div>
          )} */}
          </div>
        </div>
        <p>{coursepara}</p>
        <Link
          to="https://forms.gle/2PjqP1i92rcVTTHcA"
          target="_blank"
        >
          <button style={buttonStyle} disabled={isButtonDisabled}>
            {coursebutton}
          </button>
        </Link>
        {/* <button>{coursebutton}</button> */}
      </div>
    </>
  );
};

export default StepupCourseCards;
