import React from 'react'
import './HomeRecentWork.css'

const HomeRecentWork = ({recentWorkImg1, recentWorkHeading}) => {
  return (
    <>
        <div className="recent-work-card">
            <img loading='lazy' className='recent-work-card-head' src={recentWorkImg1} alt="" />
            <h1 className="recent-work-card-heading">{recentWorkHeading}</h1>
        </div>
    </>
  )
}

export default HomeRecentWork