import React from 'react'
import './TalentGrowthTraineeCard.css'

const TalentGrowthTraineeCard = ({ TalentTaineePara, TalentTraineeName, TalentTraineePosition, talentTraineeImage }) => {
    return (
        <>
            <div className="trainee-sayings">
                <div className="trainee-saying-left">
                    <img src={talentTraineeImage} alt="" />
                </div>
                <div className="trainee-details-sayings">
                    <p className="talent-trainee-para">{TalentTaineePara}</p>
                    <div className="talent-trainee-name-position">
                        <h1 className="talent-trainee-name"><div className="trainee-dash"></div> {TalentTraineeName}</h1>
                        <span className="talent-trainee-position">{TalentTraineePosition}</span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TalentGrowthTraineeCard