import React from 'react'
import { useState } from 'react'
import { useRef } from 'react'
import '../products/productsCard.css'                                                                             

const ProductsCard = ({data}) => {
  const sizeRef = useRef(window.innerWidth)

  // console.log(sizeRef)
  const [size, setSize] = useState(sizeRef)

  window.addEventListener("resize", function () {
    setSize(window.innerWidth)
  });

  const styles = {
    width:`${data.productsLink === 'www.hotela.com'?(size<426?'24px':'82px'):(size<426?'66px':'197px')}`
  }
  return (
    <>
    <div className="products-card">
        <img src={data.image} alt="" style={styles}/>
        <p className="products-para">{data.productsPara}</p>
        <a href="https://digihunar.com" target="_blank" className="products-link">{data.productsLink}</a>
    </div>
    </>
  )
}

export default ProductsCard