import React from 'react'
import HomeRecentWork from '../../HomeRecentWork/HomeRecentWork'
import webWork1 from '../WebsiteDevelopment/Website-Images/recent-work1.png'
import webWork2 from '../WebsiteDevelopment/Website-Images/recent-work2.png'
import webWork3 from '../WebsiteDevelopment/Website-Images/recent-work3.png'
import webWork4 from '../WebsiteDevelopment/Website-Images/recent-work4.png'
import webWork5 from '../WebsiteDevelopment/Website-Images/recent-work5.png'
import webWork6 from '../WebsiteDevelopment/Website-Images/recent-work6.png'

const WebWork = () => {
  return (
    <div className="recent-work-images">

                <HomeRecentWork recentWorkImg1={webWork1} recentWorkHeading="Fashion Website Design" />
                <HomeRecentWork recentWorkImg1={webWork2} recentWorkHeading="E - Commerce Website Design" />
                <HomeRecentWork recentWorkImg1={webWork3} recentWorkHeading="Arctica Bitcoin Website Design" />
                <div className="dsply-none">
                  <HomeRecentWork recentWorkImg1={webWork4} recentWorkHeading="Sports Website Design" />
                </div>
                <div className="dsply-none">
                  <HomeRecentWork recentWorkImg1={webWork5} recentWorkHeading="NFT Art Website Design" />
                </div>
                <div className="dsply-none">
                  <HomeRecentWork recentWorkImg1={webWork6} recentWorkHeading="Arctica Bitcoin Website Design" />
                </div>
              </div>
  )
}

export default WebWork