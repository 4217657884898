import React from "react";
import { Link } from "react-router-dom";

// Importing Images

import StepUpLogo from "./Courses-Images/stepupLogo.png";
import ModalImage from './Courses-Images/modalImage2.png'
// import ModalImage from "./Courses-Images/marketing1.png";
// import Marketing from './Courses-Images/marketing.png'
// import Designing from './Courses-Images/designing.png'
// import Graphics from './Courses-Images/graphics.png'

import { GrClose } from "react-icons/gr";

import "../CoursesModal/CoursesModal.css";

const CoursesModal = ({ showModal, setShowModal }) => {
  const handleOnClick = () => {
    setShowModal(false);
  };
  // const lastDate = new Date('Dec 11 2023').getTime();
  const lastDate = new Date('May 31, 2024 23:59:58');
  // console.log(lastDate)
  const currentDate = new Date().getTime();
  // console.log(currentDate)
  return (
    <>
      {showModal &&(currentDate<=lastDate) ? (
        <div className="courses-modal">
          <div className="courses-modal-inner">
            <div className="modal-inner-top">
              <img src={StepUpLogo} alt="Logo" />
              <div className="cross-btn">
                <GrClose onClick={handleOnClick} />
              </div>
            </div>
            <div className="modal-main">
              <div className="courses-modal-left">
                <h1 className="courses-modal-head">
                  <span className="orange">Applications are now open! </span>
                </h1>

                <ol className="modal-courses-names">
                  {/* <li>Graphic Designing</li>
                  <li>Basic Frontend Development</li>
                  <li>Advance Frontend Development</li>
                  <li>UI UX Designing</li>
                  <li>Digital Marketing</li> */}
                  <li>Applications are open for Step Up Edtech's transformative courses. Embark on a journey of skill development and unleash your full potential.</li>
                </ol>

                <div className="apply-date">
                  <span className="apply-before">Apply Before:</span>
                  <span className="last-date-apply">31 May 2024</span>
                </div>
                <div className="course-modal-buttons">
                  <Link
                    to="https://docs.google.com/forms/d/e/1FAIpQLSejgR-CFKDWKgdq8FGxaC6pfyKsiwRsLODQh_xKu8BOMnx6rA/viewform"
                    target="_blank"
                  >
                    <button
                      className="course-modal-registerNow-btn"
                      style={{ cursor: "pointer" }}
                    >
                      Register Now
                    </button>
                  </Link>
                  <Link to={"/programs/stepup"}>
                    <button className="course-modal-learnMore-btn">
                      Learn More
                    </button>
                  </Link>
                </div>
              </div>
              <div className="courses-modal-right">
                <img src={ModalImage} alt="Img Here" />
              </div>
            </div>
            {/* <div className="modal-main">
                        <div className="courses-modal-left">
                            <h1 className="courses-modal-head"><span className="orange">UI UX <span className='primary-blue'>Designing and </span><span className='orange'>Web </span></span>Development Bootcamp</h1>

                            <ol className='modal-courses-names'>
                                <li><span className="orange">UI UX Designing </span>Bootcamp</li>
                                <li><span className="orange">Basic Frontend Development </span>(HTML, CSS, JavaScript, Bootstrap)</li>
                                <li><span className="orange">Advance Frontend Development </span>(React JS)</li>
                            </ol>

                            <div className="apply-date">
                                <span className="apply-before">Apply Before:</span>
                                <span className="last-date-apply">03 Sept 2023</span>
                            </div>
                            <div className="course-modal-buttons">
                                <Link to='https://docs.google.com/forms/d/18hV0dQDB4jheznEhpsHsjpAcT4MLmDVulql2gG_70Q0/' target='_blank'>
                                    <button className="course-modal-registerNow-btn" style={{ cursor: 'pointer' }}>Register Now</button>
                                </Link>
                                <Link to={'/programs/stepup'}>
                                    <button className='course-modal-learnMore-btn'>Learn More</button>
                                </Link>
                            </div>

                        </div>
                        <div className="courses-modal-right">
                            <img src={ModalImage} alt="Img Here" />
                        </div>

                    </div> */}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default CoursesModal;
