import React, { useState,useRef } from 'react'
import { useFormik } from 'formik'
import './contact.css'
import { validationSchema } from './validationSchema'
import * as Yup from 'yup'
import emailjs from '@emailjs/browser';
import ButtonLoader from '../ButtonLoader/ButtonLoader'
import { useNavigate } from 'react-router-dom'

const Contact = () => {
    const navigate = useNavigate()
    const [loading,setLoading] = useState(false)
    const form = useRef();
    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            message: '',
          }, 
          validationSchema: Yup.object({
            name:Yup.string().max(20).min(3).required(),
            email:Yup.string().email().required(),
            message:Yup.string().required().min(50).max(500)
        }),
         
    })
    // console.log(formik)
    const sendEmail = (e) => {
        e.preventDefault();
        setLoading(true)
        emailjs.sendForm('service_mzgm8av', 'template_gxbuxsh', form.current, '6MV4X8T-gDAIeIvX7')
          .then((result) => {
            setLoading(false)
            navigate('/contact/success')
              
          }, (error) => {
              alert('Some Error Occured please Try Again');
          });
      };


    // const handleSubmit = (e)=>{
        
    //     e.preventDefault()
    //    console.log('submitted')
    // }
    return (
        <div className='contact-container'>
            <div className="max-width-1440">
                <div className="contactUs">
                    <div className="contactUs-left">
                        <h1 className="contactUs-heading">Have a <span className="primary-color">project </span> in Mind?</h1>
                        <p className="contactUs-para">Do you have an idea and you want to work on it? Let us know what services do you need?</p>
                    </div>
                    <div className="contactUs-right">
                        <h1 className="contactUs-form-heading">Contact Us</h1>
                        <form ref={form} className="contactUs-form" onSubmit={sendEmail}>
                            <div className="contact-label-input">
                                <label htmlFor="name">Name <span>*</span></label>
                                <input type="text" placeholder='Your Full Name' id='name' onChange={formik.handleChange} name='name' value={formik.values.name}/>
                                {
                                    formik.errors.name && <p className="error">{formik.errors.name}</p>
                                }
                            </div>
                            <div className="contact-label-input">
                                <label htmlFor="email">Email Address <span>*</span></label>
                                <input type="text" placeholder='Enter your Email' onChange={formik.handleChange} value={formik.values.email} id='email' name='email'/>
                                {
                                    formik.errors.email && <p className="error">{formik.errors.email}</p>
                                }
                            </div>
                            <div className="contact-label-input">
                                <label htmlFor="message">Message <span>*</span></label>
                                <textarea cols="30" rows="10" placeholder='Let us know  what services do you need.' onChange={formik.handleChange} value={formik.values.message} id='message' name='message'></textarea>
                                {
                                    formik.errors.message && <p className="error">{formik.errors.message}</p>
                                }
                            </div>

                            <button className='btn-submit' type="Submit" placeholder='Submit' disabled ={formik.values.name.length === 0|| formik.errors.name || formik.errors.email || formik.errors.message}>
                                {
                                    loading ? <ButtonLoader/> : 'Submit'
                                }
                                
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact

