import React from 'react'
import '../CareersPage.css'
import startTime from '../../CareersPage/timer-start.png'
import endTime from '../../CareersPage/timer-pause.png'
import briefCase from '../../CareersPage/briefcase.png'
import locationIcon from '../../CareersPage/location.png'
import { useState } from 'react'
import { useRef } from 'react'
const JobCard = ({ data }) => {
    const [date, setDate] = useState(0)
    const heightRef = useRef();
    const [height, setHeight] = useState(false)
    const handleLearnMore = () => {

        if (heightRef.current.style.maxHeight.length === 0 || heightRef.current.style.maxHeight === '0px') {
            setHeight(true)
            heightRef.current.style.maxHeight = '1000px'
        } else {
            setHeight(false)
            heightRef.current.style.maxHeight = '0px'
        }
    }

    const generateDate = (date) => {
        const d = new Date(date)
        return d.toDateString()
    }
    const getDateBetween = (firstDate) => {
        const date1 = new Date(firstDate);
        const date2 = new Date();
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays
    }

    const compareDates = (lastDate) => {


        if (new Date() > new Date(lastDate)) {
            return true
        } else {
            return false
        }
    }
    return (
        <div>
            <div className="careers-jobs-div">

                {/* ---- the below code is for mobile responsive ------ */}

                <div className="career-jobs-date-deadline">
                    <span className="career-job-posted-day">Posted: {getDateBetween(data.postedDate)} day ago</span>
                    <span className="career-job-post-deadline" >Deadline: <span style={{ color: `${compareDates(data.expiresAt) ? 'red' : '#9D9B9B'}` }}>{generateDate(data.expiresAt)}</span> </span>
                </div>

                {/* ----- above code is for mobile responsive ------- */}
                <div className="careers-jobs-top">
                    <div className="careers-jobs-top-left">
                        <div className="careers-jobs-posted">
                            <span className="job-titles">Posted</span>
                            <div className="icon-day-time">
                                <div className="time-date-icon-div">
                                    <img src={startTime} alt="" />
                                </div>
                                <div className="career-job-details career-job-day-posted">{getDateBetween(data.postedDate)} day ago</div>
                            </div>
                        </div>
                        <div className="careers-jobs-deadline">
                            <span className="job-titles">Deadline</span>
                            <div className="icon-day-time">
                                <div className="time-date-icon-div">
                                    <img src={endTime} alt="" />
                                </div>
                                <div className="career-job-details" style={{ color: `${compareDates(data.expiresAt) ? 'red' : '#9D9B9B'}` }}>{generateDate(data.expiresAt)}</div>
                            </div>
                        </div>
                    </div>
                    <div className="careers-jobs-top-right">
                        <div className="careers-job-type">
                            <span className="job-titles dsply-none">Type</span>
                            <div className="icon-day-time">
                                <div className="time-date-icon-div">
                                    <img src={briefCase} alt="" />
                                </div>
                                <span className="career-job-details">Full Time</span>
                            </div>
                        </div>
                        <div className="career-job-location">
                            <span className="job-titles dsply-none">Location</span>
                            <div className="icon-day-time">
                                <div className="time-date-icon-div">
                                    <img src={locationIcon} alt="" />
                                </div>
                                <span className="career-job-details">Gilgit City</span>
                            </div>
                        </div>
                    </div>
                </div>

                <h1 className="careers-jobs-heading">{data.jobTitle}</h1>
                <div className="careers-jobs-description">
                    <h1 className="careers-jobs-para"> <span className="careers-ideometrix">IdeoMetriX </span> {data.desc}</h1>

                    <div className='height-container' ref={heightRef}>
                        {/* Start */}
                        <ul className="career-job-list">

                            {
                                data.responsibilities.map((res, index) => (
                                    <li className='requirements-lists' key={index}>{res.data}</li>
                                ))
                            }
                        </ul>
                        <span className="careers-ideometrix">Requirements:</span>
                        <ul className="career-requirement-list">
                            {
                                data.requirements.map((req, index) => (
                                    <li className='requirements-lists' key={index}>{req.data}</li>
                                ))
                            }
                        </ul>

                        {
                            data.lastParas.map((para, index) => (
                                <h1 className="careers-jobs-para" key={index}>{para.data}</h1>
                            ))
                        }

                    </div>
                    {/* end */}
                </div>



                {/* ----- the below code is for mobile ----- */}

                <div className="career-job-time-city">
                    <div className="icon-day-time">
                        <div className="time-date-icon-div">
                            <img src={briefCase} alt="" />
                        </div>
                        <span className="career-job-details">Full Time</span>
                    </div>

                    <div className="icon-day-time">
                        <div className="time-date-icon-div">
                            <img src={locationIcon} alt="" />
                        </div>
                        <span className="career-job-details">Gilgit City</span>
                    </div>

                </div>

                {/* ------ The above code is for mobile ----- */}
                <div className="careers-jobs-bottom-btns">
                    <a href={data.formLink} target="_blank"><button className="careers-apply-now-btn" disabled={compareDates(data.expiresAt) ? true : false}>Apply Now</button></a>
                    <button className="careers-learn-more primary-color" onClick={handleLearnMore}>{height ? 'See Less' : 'Learn More'}</button>
                </div>
            </div>
        </div>
    )
}

export default JobCard