import React from 'react'
import '../TestimonialSlider/TestimonialSlider.css'
// import sliderQouts from '../TestimonialSlider/double-qouts.png'

const TestimonialSlider = ({ data }) => {
    return (
        <>
            <div className="testimonial-sliders">
                <div className="testimonial-slider1">
                    <img className='testimonial-qout' src={data.sliderQouts} alt="" />
                    <div className="testimonial-img-outer">

                        <img className='testimonial-img' src={data.testSliderImg} alt="" />
                    </div>
                    <p className="testimonial-slider-para">{data.testSliderPara}</p>
                    <div className="testimonial-slider-bottom">
                        <p className="testimonial-p primary-color">{data.testClientName}</p>
                        <p className='testimonial-bottom-p'>{data.testClientPosition}</p>
                    </div>

                </div>
            </div>
        </>
    )
}

export default TestimonialSlider