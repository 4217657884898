import React from 'react'
import '../EmployeesCards/EmployeeCard.css'
import linkedInIcon from '../../pages/About/About-Imagess/linkedin.png'

const EmployeeCard = ({data}) => {
  return (
    <>
        <div className="employee-card">
            <img className='employee-img' src={data.employeeImage} alt="" />
            <span className="employee-name">{data.employeeName}</span>
            <span className="employee-position">{data.employeePosition}</span>
            <div className="employee-social-account">
                <span className="followat">Follow at</span>
                <a href={data.link} target="_blank"><img className='linkedin-img' src={linkedInIcon} alt="" /></a>
            </div>
        </div>
    </>
  )
}

export default EmployeeCard